.popup{
    width: 20em;
}

.popup-text{
    font-size: small !important;
}

.table-body{
    max-height: 10em;
    overflow-y: auto;
}