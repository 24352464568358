.sidebar-nav{
    height: 2.7em;
}

.sidebar-body{
    height: calc(100% - 2.7em);
}

.logo{
    cursor: pointer;
    height: 2.5em;
}