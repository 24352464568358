.notification{
    width: 20em !important;
}
*{
    font-size: small;
}

.text-cut{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}